// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/user","layout":false,"id":"1"},"2":{"name":"login","path":"/user/login","parentId":"1","id":"2"},"3":{"path":"/welcome","name":"welcome","icon":"smile","parentId":"ant-design-pro-layout","id":"3"},"4":{"path":"/client","name":"client","icon":"solution","parentId":"ant-design-pro-layout","id":"4"},"5":{"path":"/client","parentId":"4","id":"5"},"6":{"path":"/client/brief/:id","name":"brief","hideInMenu":true,"parentId":"4","id":"6"},"7":{"path":"/contracts","name":"contracts","icon":"file","parentId":"ant-design-pro-layout","id":"7"},"8":{"path":"/staff","name":"staff","icon":"team","parentId":"ant-design-pro-layout","id":"8"},"9":{"path":"/staff","parentId":"8","id":"9"},"10":{"path":"/ecommerce","name":"ecommerce","icon":"shopping","parentId":"ant-design-pro-layout","id":"10"},"11":{"path":"/ecommerce/categories","name":"categories","parentId":"10","id":"11"},"12":{"path":"/ecommerce/brands","name":"brands","parentId":"10","id":"12"},"13":{"path":"/ecommerce/products","name":"products","parentId":"10","id":"13"},"14":{"path":"/account","name":"account","icon":"user","parentId":"ant-design-pro-layout","id":"14"},"15":{"path":"/account/center","name":"center","parentId":"14","id":"15"},"16":{"path":"/account/settings","name":"settings","parentId":"14","id":"16"},"17":{"path":"/","redirect":"/welcome","parentId":"ant-design-pro-layout","id":"17"},"18":{"path":"*","layout":false,"id":"18"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__User__Login__index" */'@/pages/User/Login/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Welcome" */'@/pages/Welcome.tsx')),
'4': React.lazy(() => import('./EmptyRoute')),
'5': React.lazy(() => import(/* webpackChunkName: "p__Client__index" */'@/pages/Client/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__Client__Brief__index" */'@/pages/Client/Brief/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__Contract__index" */'@/pages/Contract/index.tsx')),
'8': React.lazy(() => import('./EmptyRoute')),
'9': React.lazy(() => import(/* webpackChunkName: "p__Staff__index" */'@/pages/Staff/index.tsx')),
'10': React.lazy(() => import('./EmptyRoute')),
'11': React.lazy(() => import(/* webpackChunkName: "p__Ecommerce__Categories__index" */'@/pages/Ecommerce/Categories/index.tsx')),
'12': React.lazy(() => import(/* webpackChunkName: "p__Ecommerce__Brands__index" */'@/pages/Ecommerce/Brands/index.tsx')),
'13': React.lazy(() => import(/* webpackChunkName: "p__Ecommerce__Products__index" */'@/pages/Ecommerce/Products/index.tsx')),
'14': React.lazy(() => import('./EmptyRoute')),
'15': React.lazy(() => import(/* webpackChunkName: "p__Account__Center__index" */'@/pages/Account/Center/index.tsx')),
'16': React.lazy(() => import(/* webpackChunkName: "p__Account__Settings__index" */'@/pages/Account/Settings/index.tsx')),
'17': React.lazy(() => import('./EmptyRoute')),
'18': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/builds/checkmate/maido-ecommerce/frontend/crm/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
