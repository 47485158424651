import type { TError } from '@/types/common';

class ErrorUtils {
  static getErrorMsgFromAPI = (err: ExplicitAny): string => {
    let message = 'Unknown error';

    if (!err) return message;

    const errorContent = err.error || err;

    if (typeof errorContent === 'string') {
      return this.localizeErrorMessage(errorContent);
    }

    if (typeof errorContent === 'object') {
      const firstFieldKey = Object.keys(errorContent)[0]!;
      const firstField = errorContent[firstFieldKey];

      if (firstField?.message) {
        message = firstField.message;
      } else if (Array.isArray(firstField)) {
        message =
          firstField.length > 0 && firstField[0].message
            ? firstField[0].message
            : 'Unknown field error';
      } else if (typeof firstField === 'string') {
        message = firstField;
      }
    } else if (err.message) {
      message = err.message;
    }

    return this.localizeErrorMessage(message);
  };

  private static localizeErrorMessage = (message: TError): string => {
    const localizationMap: Record<string, string> = {
      'Product payment status must be unpaid to update.':
        'Không thể cập nhật sản phẩm đã thanh toán',
      'Unknown error': 'Lỗi không xác định',
      'Order status must be editing or ready to delete.':
        'Xác nhận đơn hàng phải là Editing/Ready thì mới có thể xóa',
      'You do not have permission to perform this action.':
        'Bạn không có đủ quyền để thực hiện hành động này.',
      'No OrderTransaction matches the given query.': 'Không tìm thấy xác nhận thanh toán',
      'Unable to log in with provided credentials.': 'Thông tin đăng nhập không chính xác',
    };

    return localizationMap[message] ?? message;
  };
}

export default ErrorUtils;
