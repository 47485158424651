import { ProTable, type ProTableProps } from '@ant-design/pro-components';
import { Input } from 'antd';
import type React from 'react';
import { useMemo } from 'react';

type TCustomTableProps<
  DataSource = any,
  U = Record<string, any>,
  ValueType = 'text',
> = ProTableProps<DataSource, U, ValueType> & {
  searchValue?: string;
  onChangeSearch?: (newValue: string) => void;
};

const CustomTable: React.FC<TCustomTableProps> = ({
  searchValue,
  onChangeSearch,
  toolBarRender,
  ...props
}) => {
  const cookedToolBarRender = useMemo(() => {
    const toolbars = [
      <Input.Search
        key="search-table"
        placeholder="Tìm kiếm..."
        value={searchValue}
        onChange={(e) => onChangeSearch?.(e.target.value)}
      />,
    ];

    // Check if toolBarRender is a function before calling it
    if (typeof toolBarRender === 'function') {
      const actionMock = undefined; // Replace with the actual action if you have it
      const rowsMock = { selectedRowKeys: [], selectedRows: [] }; // Adjust as necessary
      const toolbarResult = toolBarRender(actionMock, rowsMock);

      if (Array.isArray(toolbarResult)) {
        return [...toolbars, ...toolbarResult];
      }
    }

    return toolbars;
  }, [toolBarRender, searchValue, onChangeSearch]);

  return (
    <ProTable
      search={false}
      toolBarRender={() => cookedToolBarRender}
      scroll={{ x: 'max-content', y: 'calc(100vh - 380px)' }}
      bordered
      {...props}
    />
  );
};
export default CustomTable;
