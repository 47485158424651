import type React from 'react';
import { useEffect, useRef } from 'react';
import ReactQuill, { type ReactQuillProps } from 'react-quill';

export type TCustomQuillProps = Omit<ReactQuillProps, 'onChange' | 'value'> & {
  onChange?: (value: string) => void;
  value?: string;
};

const CustomQuill: React.FC<TCustomQuillProps> = ({ onChange, value, ...props }) => {
  const quillRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      if (editor.root.innerHTML !== value) {
        editor.root.innerHTML = value ?? '';
      }
    }
  }, [value]);

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();

      const handleTextChange = () => {
        const html = editor.root.innerHTML;
        if (html === '<p><br></p>') {
          onChange?.('');
        } else {
          onChange?.(html);
        }
      };

      editor.on('text-change', handleTextChange);

      return () => {
        editor.off('text-change', handleTextChange); // Clean up listener
      };
    }
  }, [onChange]);

  return (
    <ReactQuill
      modules={{
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link'],
        ],
      }}
      theme="snow"
      ref={quillRef}
      {...props}
    />
  );
};

export default CustomQuill;

export { default as DisplayQuill } from './DisplayQuill';
