import DOMPurify from 'dompurify';
import type React from 'react';
import './DisplayQuill.css';
export type TDisplayQuillProps = {
  content: string;
};

const DisplayQuill: React.FC<TDisplayQuillProps> = ({ content }) => {
  return (
    <div
      className="ql-editor display-quill"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
    ></div>
  );
};

export default DisplayQuill;
