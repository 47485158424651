import axios, { type AxiosRequestConfig } from 'axios';
import { AxiosConfig } from '@/config/axios';
import type { TFetchOptions, TPagedResponse, TResponse } from '@/types/api';
import { isSuccess } from '@/utils/statusCode';
import { ErrorUtils, MapperUtils } from '@/utils';
import { message } from 'antd';
import { ROUTES_AUTH } from '@/features/auth/routes';
import { AppConfig } from '@/config/app';

export const publicAxios = axios.create({
  timeout: AxiosConfig.DEFAULT_TIMEOUT,
  baseURL: AxiosConfig.DEFAULT_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const privateAxios = axios.create({
  timeout: AxiosConfig.DEFAULT_TIMEOUT,
  baseURL: AxiosConfig.DEFAULT_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

let isRefreshToken = false;
let requestQueue: ExplicitAny[] = [];

publicAxios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

publicAxios.interceptors.response.use((response) => {
  const { data } = response;
  if (data.status_code !== 1200) {
    const errorMsg = ErrorUtils.getErrorMsgFromAPI(data.error);
    message.error(errorMsg);
  }
  return response;
});

const refreshNewToken = async () => {
  try {
    const res = await publicAxios<any>(ROUTES_AUTH.refresh(), {
      method: 'POST',
      data: {
        refresh: '',
      },
    });
    if (res.data.status_code === 1200) return res.data.data;
    return null;
  } catch (err: any) {
    return null;
  }
};

privateAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token.replace(/"/g, '')}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

privateAxios.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data.status_code !== 1200) {
      const errorMsg = ErrorUtils.getErrorMsgFromAPI(data.error);
      message.error(errorMsg);
    }
    return response;
  },
  async (error: any) => {
    if (error.response) {
      if (error.response.status === 401) {
        const { config } = error.response;
        if (
          config?.url.includes('/auth/token/refresh') ||
          config?.url.includes('/auth/token/login')
        ) {
          localStorage.removeItem('accessToken');
          return Promise.reject(error);
        }
        if (!isRefreshToken) {
          isRefreshToken = true;
          const token = await refreshNewToken();
          if (token) {
            localStorage.setItem('accessToken', JSON.stringify(token.access));
            config.headers.Authorization = `Bearer ${token.access}`;
            requestQueue.forEach((cb) => cb(token.access));
            isRefreshToken = false;
            requestQueue = [];
            return privateAxios(config);
          }
        } else {
          return new Promise((resolve, reject) => {
            requestQueue.push((token: any) => {
              if (token) {
                config.headers.Authorization = `Bearer ${token.access}`;
                resolve(privateAxios(config));
              } else {
                reject(error);
              }
            });
          });
        }
      }
    }
    localStorage.removeItem('accessToken');
    return Promise.reject(error);
  },
);

export const fetchSingledData = async <T, U>(
  endpoint: string,
  opts?: AxiosRequestConfig | Record<string, any>,
) => {
  const res = await privateAxios<TResponse<T>>(endpoint, opts);

  if (!isSuccess(res.data.status_code)) {
    const errorMsg = ErrorUtils.getErrorMsgFromAPI(res.data.error);
    throw new Error(errorMsg);
  }

  const resultMapped = MapperUtils.mapResponseToDTO<U>(res.data.data);
  return resultMapped;
};

export const fetchPaginatedData = async <T, U>({
  page = AppConfig.DEFAULT_PAGE_INDEX,
  pageSize = AppConfig.DEFAULT_PAGE_SIZE,
  ...options
}: TFetchOptions) => {
  const fillteringMapped = MapperUtils.mapFilteringTableToString(options.filtering);
  const ordering = options.ordering
    ? MapperUtils.mapSortTableToString(options.ordering)
    : undefined;

  const res = await privateAxios.get<TResponse<TPagedResponse<T>>>(options.endpoint, {
    params: {
      ...fillteringMapped,
      ordering,
      page: page,
      size: pageSize,
      search: options.search,
    },
  });

  if (!isSuccess(res.data.status_code)) {
    const errorMsg = ErrorUtils.getErrorMsgFromAPI(res.data.error);
    throw new Error(errorMsg);
  }

  const resultsMapped = res.data.data.results.map((result) =>
    MapperUtils.mapResponseToDTO<U>(result),
  );
  return {
    data: resultsMapped,
    totalPages: res.data.data.total_pages,
    hasNextPage: res.data.data.next,
    hasPreviousPage: res.data.data.previous,
    count: res.data.data.count,
  };
};

export const createData = async <T, U>(
  endpoint: string,
  payload?: T,
  opts?: AxiosRequestConfig | Record<string, any>,
) => {
  const payloadMapped = MapperUtils.mapDTOToResponse<U>(payload);

  const res = await privateAxios.post<TResponse<any>>(endpoint, payloadMapped, opts);

  if (!isSuccess(res.data.status_code)) {
    const errorMsg = ErrorUtils.getErrorMsgFromAPI(res.data.error);
    throw new Error(errorMsg);
  }

  return res.data;
};

export const updateData = async <T, U>(
  endpoint: string,
  payload?: T,
  opts?: AxiosRequestConfig | Record<string, any>,
) => {
  const payloadMapped = MapperUtils.mapDTOToResponse<U>(payload);

  const res = await privateAxios.put<TResponse<any>>(endpoint, payloadMapped, opts);

  if (!isSuccess(res.data.status_code)) {
    const errorMsg = ErrorUtils.getErrorMsgFromAPI(res.data.error);
    throw new Error(errorMsg);
  }

  return res.data;
};

export const updateDataWithPatch = async <T, U>(
  endpoint: string,
  payload?: T,
  opts?: AxiosRequestConfig | Record<string, any>,
) => {
  const payloadMapped = MapperUtils.mapDTOToResponse<U>(payload);

  const res = await privateAxios.patch<TResponse<any>>(endpoint, payloadMapped, opts);

  if (!isSuccess(res.data.status_code)) {
    const errorMsg = ErrorUtils.getErrorMsgFromAPI(res.data.error);
    throw new Error(errorMsg);
  }

  return res.data;
};

export const fetchListData = async <T, U>({ ...options }: TFetchOptions) => {
  const fillteringMapped = MapperUtils.mapFilteringTableToString(options.filtering);
  const ordering = options.ordering
    ? MapperUtils.mapSortTableToString(options.ordering)
    : undefined;

  const res = await privateAxios.get<TResponse<T[]>>(options.endpoint, {
    params: {
      ...fillteringMapped,
      ordering,
      search: options.search,
    },
  });

  if (!isSuccess(res.data.status_code)) {
    const errorMsg = ErrorUtils.getErrorMsgFromAPI(res.data.error);
    throw new Error(errorMsg);
  }

  const resultsMapped = res.data.data.map((result) => MapperUtils.mapResponseToDTO<U>(result));
  return resultsMapped;
};

export const deleteData = async (
  endpoint: string,
  opts?: AxiosRequestConfig | Record<string, ExplicitAny>,
) => {
  const res = await privateAxios.delete<TResponse<ExplicitAny>>(endpoint, opts);

  if (!isSuccess(res.data.status_code)) {
    const errorMsg = ErrorUtils.getErrorMsgFromAPI(res.data.error);
    throw new Error(errorMsg);
  }

  return res.data;
};
