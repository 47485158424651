// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import SmileOutlined from '/builds/checkmate/maido-ecommerce/frontend/crm/node_modules/@ant-design/icons/es/icons/SmileOutlined';
import SolutionOutlined from '/builds/checkmate/maido-ecommerce/frontend/crm/node_modules/@ant-design/icons/es/icons/SolutionOutlined';
import FileOutlined from '/builds/checkmate/maido-ecommerce/frontend/crm/node_modules/@ant-design/icons/es/icons/FileOutlined';
import TeamOutlined from '/builds/checkmate/maido-ecommerce/frontend/crm/node_modules/@ant-design/icons/es/icons/TeamOutlined';
import ShoppingOutlined from '/builds/checkmate/maido-ecommerce/frontend/crm/node_modules/@ant-design/icons/es/icons/ShoppingOutlined';
import UserOutlined from '/builds/checkmate/maido-ecommerce/frontend/crm/node_modules/@ant-design/icons/es/icons/UserOutlined';
export default { SmileOutlined, SolutionOutlined, FileOutlined, TeamOutlined, ShoppingOutlined, UserOutlined };
