import type { Filters, Sorts } from '@/hooks/useTableState';
import type { TCommonImage, TImage } from '@/types/common';
import TransformUtils from '@/utils/transform';
import type { TImageSchema } from '@/validations/common';

class MapperUtils {
  // Helper to convert keys in an object based on a provided key mapping and a case converter
  static convertKeysWithMapping = <T>(
    obj: ExplicitAny,
    caseConverter: (key: string) => string,
    keyMapping: Record<string, string> = {},
  ): T => {
    if (Array.isArray(obj)) {
      return obj.map((item) =>
        this.convertKeysWithMapping(item, caseConverter, keyMapping),
      ) as unknown as T;
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const mappedKey = keyMapping[key] ?? caseConverter(key);
        acc[mappedKey] = this.convertKeysWithMapping(obj[key], caseConverter, keyMapping);
        return acc;
      }, {} as ExplicitAny) as T;
    }
    return obj;
  };

  // Converts a response from snake_case (API) to camelCase (DTO) format with optional mappings
  static mapResponseToDTO = <T>(data: ExplicitAny, keyMapping: Record<string, string> = {}): T =>
    MapperUtils.convertKeysWithMapping<T>(data, TransformUtils.toCamelCase, keyMapping);

  // Converts a DTO from camelCase to snake_case format for API (POST/PUT requests) with optional mappings
  static mapDTOToResponse = <T>(dto: ExplicitAny, keyMapping: Record<string, string> = {}): T =>
    MapperUtils.convertKeysWithMapping<T>(dto, TransformUtils.toSnakeCase, keyMapping);

  static mapSortTableToString = (orderings: Sorts) => {
    try {
      const orderingStringArray = Object.keys(orderings).reduce<string[]>((acc, ordering) => {
        const orderingSnakeCase = TransformUtils.toSnakeCase(ordering);
        if (orderings[ordering] === 'descend') {
          acc.push(`-${orderingSnakeCase}`);
        } else {
          acc.push(orderingSnakeCase);
        }
        return acc;
      }, []);
      return orderingStringArray.join(',');
    } catch {
      return '';
    }
  };

  static mapFilteringTableToString = (orderings?: Filters) => {
    if (orderings) {
      try {
        return Object.keys(orderings).reduce<Record<string, string | undefined>>((acc, value) => {
          const valueSnakeCase = TransformUtils.toSnakeCase(value);
          if (!orderings[value]) return acc;
          if (Array.isArray(orderings[value])) {
            acc[valueSnakeCase] = orderings[value].join(',');
          } else {
            acc[valueSnakeCase] = JSON.stringify(orderings[value]);
          }
          return acc;
        }, {});
      } catch {
        return {};
      }
    }
    return {};
  };

  static mapImageSchemaToTImage = (imageSchema: TImageSchema) => {
    return {
      imageUrl: imageSchema.objectKey ?? '',
      altText: imageSchema.name ?? '',
      fileName: imageSchema.name ?? '',
      fileSize: imageSchema.size ?? 0,
      fileType: imageSchema.type ?? '',
    } satisfies TImage;
  };

  static mapTImageToImageSchema = (imageSchema: TImage) => {
    return {
      objectKey: imageSchema.imageUrl,
      url: imageSchema.imageUrlUrl,
      size: imageSchema.fileSize,
      type: imageSchema.fileType,
      name: imageSchema.fileName,
    } satisfies TImageSchema;
  };

  static mapImagesSchemaToArrayString = (imagesSchema: TImageSchema[]) => {
    return imagesSchema
      .map((image) => image.objectKey)
      .filter((key): key is string => key !== undefined);
  };
  static mapCommonImagesToImagesSchema = (images: TCommonImage[]) => {
    return images.map((image) => ({
      objectKey: image.imageUrl,
      url: image.imageLink,
      uid: image.imageUrl,
    })) satisfies TImageSchema[];
  };
}

export default MapperUtils;
