'use client';

import { history } from '@umijs/max';
import React, { createContext, useEffect, useMemo, useState } from 'react';

export type TDrawerStackValue = {
  /**
   * Opens a drawer using the provided component and props
   */
  openDrawer: <T extends TStackedDrawerProps, P extends T>(
    component: React.ComponentType<T>,
    props?: Omit<P, keyof TStackedDrawerProps>,
    options?: TOpenDrawerOptions,
  ) => any;

  /**
   * Closes the active drawer
   */
  closeDrawer: () => void;

  /**
   * Closes the number of drawers
   */
  closeDrawers: (amount?: number) => void;

  /**
   * Closes all drawers
   */
  closeAllDrawers: () => void;

  stack: TStackedDrawer[];
};

export type TOpenDrawerOptions = {
  /**
   * Replaces the active modal in the stack
   */
  replace?: boolean;
};

export interface TStackedDrawerProps {
  isOpen?: boolean;
}

export type TStackedDrawer = {
  component: React.ComponentType;
  props: any;
};

export interface TDrawerStackProps {
  renderBackdrop?: React.ComponentType<any>;
  renderDrawers?: React.ComponentType<TDrawerStackValue>;
  children?: React.ReactNode;
}

export const Drawers: React.FC<TDrawerStackValue> = ({ stack, closeAllDrawers }) => {
  useEffect(() => {
    closeAllDrawers();
  }, [history.location]);
  return (
    <>
      {stack.map((drawer, index) => {
        return (
          <drawer.component
            key={`custom-drawer-${index}`}
            open={drawer === stack[stack.length - 1]}
            {...drawer.props}
          />
        );
      })}
    </>
  );
};

export const DrawerStackContext = createContext<TDrawerStackValue>({} as any);

const DrawerStack: React.FC<TDrawerStackProps> = ({
  children,
  renderDrawers: DrawersComponent = Drawers,
}) => {
  const [stack, setStack] = useState<TStackedDrawer[]>([]);

  const value = useMemo<TDrawerStackValue>(() => {
    const pop = (amount = 1) => {
      return setStack((prev) => [...prev].slice(0, prev.length - amount));
    };
    const dismissAll = () => {
      setStack([]);
    };
    const dismiss = (amount?: number) => {
      if (stack.length === 1) {
        dismissAll();
      } else {
        pop(amount);
      }
    };
    return {
      stack,
      openDrawer: (component, props, options) => {
        setStack((prev) => {
          let newStack = [...prev];
          if (options?.replace) {
            newStack = stack.slice(0, stack.length - 1);
          }
          return [...newStack, { component, props } as TStackedDrawer];
        });
      },
      closeDrawer: () => dismiss(1),
      closeAllDrawers: dismissAll,
      closeDrawers: dismiss,
    };
  }, [stack]);

  return (
    <DrawerStackContext.Provider value={value}>
      {children}
      <DrawersComponent {...value} />
    </DrawerStackContext.Provider>
  );
};

export default DrawerStack;
