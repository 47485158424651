import { ROUTES_AUTH } from '@/features/auth/routes';
import type { TUser, TUserResponseDTO } from '@/features/user/user';
import { fetchSingledData, publicAxios } from '@/libs/axios';
import { TLoginPayload } from '@/services/auth/typings';
import { TResponse } from '@/types/api';

export class AuthService {
  static async login(payload: TLoginPayload) {
    const res = await publicAxios<TResponse<any>>(ROUTES_AUTH.login(), {
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  static async logout() {
    const res = await publicAxios<TResponse<any>>(ROUTES_AUTH.logout(), {
      method: 'POST',
      withCredentials: true,
    });
    return res;
  }

  static async getUser() {
    const res = await fetchSingledData<TUserResponseDTO, TUser>(ROUTES_AUTH.getUser());
    return res;
  }
}
