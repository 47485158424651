class TransformUtils {
  // Convert snake_case string to camelCase string
  static toCamelCase(str: string): string {
    return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
  }

  // Convert camelCase string to snake_case string
  static toSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }

  // Convert all keys in an object from snake_case to camelCase
  static convertKeysToCamelCase<T>(obj: ExplicitAny): T {
    if (Array.isArray(obj)) {
      return obj.map((item) => this.convertKeysToCamelCase(item)) as unknown as T;
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const camelKey = this.toCamelCase(key);
        acc[camelKey] = this.convertKeysToCamelCase(obj[key]);
        return acc;
      }, {} as ExplicitAny) as T;
    }
    return obj;
  }

  // Convert all keys in an object from camelCase to snake_case
  static convertKeysToSnakeCase<T>(obj: ExplicitAny): T {
    if (Array.isArray(obj)) {
      return obj.map((item) => this.convertKeysToSnakeCase(item)) as unknown as T;
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const snakeKey = this.toSnakeCase(key);
        acc[snakeKey] = this.convertKeysToSnakeCase(obj[key]);
        return acc;
      }, {} as ExplicitAny) as T;
    }
    return obj;
  }
}

export default TransformUtils;
